<template>
  <v-row class="loginRow" align="center" justify="center">
    <v-col cols="12" sm="8" md="4" class="loginCol">
      <div class="bell--element">
        <img src="@/assets/bell-second.png" />
      </div>
      <div class="letter--element">
        <img src="@/assets/letter.png" />
      </div>
      <v-card style="border-radius: 10px;" class="loginCard">
        <div>
          <img src="@/assets/big-logo.png" />
        </div>
        <v-card-text>
          <v-form class="loginForm" @submit.prevent="resetPassword">
            <v-text-field
              height="81"
              :class="!$vuetify.breakpoint.smAndDown && 'login--input--field'"
              @keypress.enter="resetPassword"
              filled
              rounded
              dense
              v-model="password"
              placeholder="Password"
              required
              background-color="#e9e9e9"
              type="password"
              x-large
            />
            <v-btn
              height="77"
              x-large
              class="loginBtn"
              @click.prevent="resetPassword"
              >Change password</v-btn
            >
          </v-form>
          <p v-if="feedback" class="text-uppercase red--text">{{ feedback }}</p>
        </v-card-text>
        <div class="login-slogan">
          <h3>HR is a work of <span>♥</span></h3>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "PWresetView",
  data() {
    return {
      password: "",
      feedback: ''
    };
  },
  methods: {
    async resetPassword() {
      try {
        console.log({
          token: this.$route.query.token,
          userId: this.$route.query.id,
          password: this.password,
        });
        await axios.post("/api/recovery/resetPassword", {
          token: this.$route.query.token,
          userId: this.$route.query.id,
          password: this.password,
        });
        this.$router.push({ name: "login" });
      } catch (error) {
        this.feedback =
          "Failed to change password, be sure that passwords match";
      }
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="scss" scoped>
* {
  font-family: "Nunito", sans-serif;
}
.letter--element {
  position: absolute;
  left: -70px;
  bottom: -20px;
}
.bell--element {
  position: absolute;
  top: -70px;
  right: -30px;
}
.loginCol {
  position: relative;
}
.login-slogan {
  padding-bottom: 25px;
  h3 {
    font-size: 24px;
    font-weight: 400;
    color: #bababa;
  }
  span {
    color: blue;
    font-size: 25px;
  }
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: gray;
}

.loginRow {
  height: 100vh;
  width: 100vw;
  img {
    max-width: 150px;
    margin-top: 20px;
  }
}
.v-input--checkbox {
  justify-content: center;
}
.v-card__actions {
  display: grid;
  grid-auto-flow: column;
}
.loginCard {
  height: 100%;
  width: 100%;
  z-index: 2;
  padding-right: 5%;
  padding-left: 5%;
  background: transparent padding-box 0% 0% no-repeat padding-box;
  border: 1px solid #bababa3c;
  border-radius: 20px;
  opacity: 1;
  backdrop-filter: blur(5px);
  max-width: 540px;
  position: relative;
  img {
    width: 150px;
    max-width: 150px;
  }
}
.loginForm {
  height: 100%;
  padding-bottom: 10%;
  padding-top: 10%;
}
.loginBtn {
  box-shadow: 0px 5px 15px #0000000f;
  border-radius: 39px;
  background: linear-gradient(259deg, #604fe2 0%, #772276 100%) 0% 0% no-repeat
    padding-box;
  color: #ffffff;
  width: 100%;
  justify-self: end;
  font-size: 24px;
  font-weight: 900;
}
@media only screen and (max-width: 600px) {
  .backImage {
    width: 100%;
  }
  .loginRow {
    margin-right: 0;
    overflow: hidden;
  }
}
.login--input--field {
  border-radius: 40px;
  font-size: 24px;
  color: #bababa;
}
</style>
